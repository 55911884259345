import * as React from 'react';

import Layout from 'shared/CourseIeltsDetail/Layout';
import Problem from 'shared/CourseIeltsDetail/section/Problem';
import IeltsCapTocDifferencesMain from 'shared/CourseIeltsDetail/section/IeltsCapTocDifferencesMain';
import { Course } from 'shared/CourseIeltsDetail/section/Course';

const ieltsDesc =
  'Với chương trình học tinh gọn, phương pháp Linearthinking tư duy thông minh, sự tận tâm theo sát của giáo viên và chính sách đảm bảo đầu ra, bạn sẽ tăng được 1.5 band IELTS sau 2 tháng kèm xây được nền Tiếng Anh & tư duy ngôn ngữ vững chắc.';

const CoursePage = ({ location }) => {
  return (
    <Layout
      slug={location.pathname}
      title="IELTS"
      highlight="cấp tốc"
      ieltsDesc={ieltsDesc}
      featureImg="efF6C6QgQEeXutC2PHtS"
      seoTitle="Tổng hợp khóa học IELTS Cấp Tốc Cam Kết đầu ra tăng band nhanh chóng"
      seoDesc="DOL English là trung tâm luyện IELTS cấp tốc có đảm bảo đầu ra với chương trình học tinh gọn, phương pháp Linearthinking tư duy thông minh độc quyền ở Việt Nam"
    >
      <Problem level="crash" />
      <IeltsCapTocDifferencesMain />
      <Course
        title="Các khóa IELTS cấp tốc tại DOL"
        level="Khóa IELTS cấp tốc"
        centerLevel
      />
    </Layout>
  );
};

export default React.memo(CoursePage);
